// useSharedItemPageState - React Hook for the Item pages to navigate
// between themselves and pass data around so each page doesn't
// have to reload from the database each time.
//
// Also contain shared UI components for navigating between the
// pages, like Breadcrumbs.
//
// Also exports these page names:
//    pageItem - Page name to pass to navigateTo to go to an item
//    pageTask - Page name to pass to navigateTo to go to a task
//    pageLogbook - Page nmae to pass to navigateTo to go to the logbook.
//    pageLogbookEntry - Page name to pass to navigateTo to go to a logbook entry.
//
// See below for usage of useSharedItemPageState.

import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import ItemBreadcrumbs from './ItemBreadcrumbs';
import { bucketNameOverdue, bucketNameSoon, bucketNameAll, urlForItem, urlForEmailItem, urlForTaskId, urlForLogbook, urlForLogbookPickTask, urlForTasks, urlForLogbookEntry, urlForLogbookEntryFromTask } from '../../fleet-shared/Urls.mjs';
import * as Database from '../../utilities/Database.mjs';
import * as TaskBuckets from '../../fleet-shared/TaskBuckets.mjs';

// Page names
export const pageItem = 'item';
export const pageEmailItem = 'emailItem';
export const pageTask = 'task';
export const pageLogbook = 'logbook';
export const pageLogbookEntry = 'logbookEntry';
export const pageLogbookEntryFromTask = 'logbookEntryFromTask';
export const pageLogbookPickTask = 'logbookPickTask';
export const pageTasks = 'tasks';

// Inputs:
//   page - one of pageItem, pageEmailItem, pageTask, pageTasks, pageLogbook, pageLogbookEntry, pageLogbookEntryFromTask, pageLogbookPickTask (exported from this file).
//   itemId - ItemId this state is for.
//   pageItemId - The sub-item id (so TaskId, LogbookEntryId, 'all'/'soon'/'overdue', etc.) for this page. Undefined for pageLogbook.
//   showLoading - Optional function to call to show the loading indicator.
//   hideLoading - Optional function to call to hide the loading indicator.
//   showError - Optional function to call to show error during loading.
//   mounted - a useRef reference to if the page this state is attached to is still mounted (cuz I do async stuff here and the user might nav before).
//   
// Returns [{...}], where each item in the map is:
//   item - The Item for the page
//   task - The Task for the page.
//   completedTask - The CompletedTask for the page
//   setCompletedTask - Sets completedTask (e.g. for editing on a page)
//   taskBucket - The task bucket (or the Tasks page). all/soon/overdue
//   entries - [CompletedTask] for the Logbook page.
//   setEntries - sets entries (e.g. adding an entry on Logbook page).
//   ItemBreadcrumbs - ItemBreadcrumbs component to render at top of page.
//   navigateTo(page, pageItemId) - Navigates (and passes state) to the given page (see page variables below). pageItemId is the taskId, LogbookEntryId or urlType for types.
export const useSharedItemPageState = ({
    page, itemId, pageItemId, showLoading, hideLoading, showError, mounted
}) => {
    // Cached data for the pages.
    const [item, setItem] = useState(null);
    const [task, setTask] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [completedTask, setCompletedTask] = useState(null);
    const [taskBucket, setTaskBucket] = useState(null);
    const [entries, setEntries] = useState(null);
    // const [currentPage, setCurrentPage] = useState(null);

    const location = useLocation();

    // When load, pull any data from the state.
    useEffect( () => {
        let mounted = true;
        function checkMounted() {
            if (!mounted) {
                throw(new Error('Not mounted'));
            }
        }

        async function loadItem() {
            if (location.state && location.state.item && location.state.item.id === itemId) {
                setItem(location.state.item);

                // I only want to pull out of state once, so if the user goes back or reloads,
                // I load from the database. Otherwise can see stale date.
                // TODO: For other state stuff, if not on right page time to clear too...
                let newState = {...location.state}
                delete newState.item;
                window.history.replaceState(newState, document.title)

                return location.state.item;
            } else {
                console.info(`Loading item ${itemId}`);
                let item = await Database.retrieveItem(itemId);
                if (null === item) { throw new Error('Item not found'); }
                console.info(`Loaded item ${itemId}, ${item && item.name}`);
                checkMounted();
                setItem(item);
                return item;
            }
        }

        async function fetch() {
            try {
                if (showLoading) { showLoading(); };

                // Remove stale item and load new if needed.
                let item = await loadItem();

                switch (page) {
                case pageItem:
                case pageEmailItem:
                    // Just needs the item.
                    break;
                case pageTask:
                case pageLogbook:
                case pageLogbookPickTask:
                    // Data for these are loaded in another effect, when the item changes during loadItem above.
                    // TODO: Why not here?
                    break;
                case pageLogbookEntry:
                case pageLogbookEntryFromTask:
                    // Page needs a CompletedTask and optionally a Task if want to edit task this was based on.
                    // I only setCompletedTask and setTask when I have both loaded, so the UI shows both pieces
                    // at the same time.

                    // Editing the task, too?
                    let editTask = (page === pageLogbookEntryFromTask);

                    // First see if I have CompletedTask in state. Load it otherwise.
                    let loadedCompletedTask = null;
                    let loadedTask = null;

                    if (location.state && location.state.completedTask && location.state.completedTask.id === pageItemId) {
                        loadedCompletedTask = location.state.completedTask;

                        // I only want to pull out of state once, so if the user goes back or reloads,
                        // I load from the database. Otherwise can see stale date.
                        let newState = {...location.state};
                        delete newState.completedTask;
                        window.history.replaceState(newState, document.title)
                    } else {
                        console.info(`Loading completed task ${pageItemId}`);
                        loadedCompletedTask = await Database.retrieveCompletedTask(pageItemId);
                        if (null === loadedCompletedTask) { 
                            throw new Error('Logbook entry not found'); 
                        }
                        // Task came from DB with the CompletedTask.
                        if (editTask) {
                          loadedTask = loadedCompletedTask.Task;
                        }
                        console.info(`Loaded completedTask ${pageItemId}, ${loadedCompletedTask && loadedCompletedTask.name}`);
                    }
                    checkMounted();

                    // Second, if page needs task, load the Task from state or DB.
                    if (editTask) { // Does this page need the task?
                      if (location.state && location.state.task && location.state.task.id === loadedCompletedTask.taskId) {
                          // If I just loaded the task from the DB, it's more fresh than state, so use it.
                          if (null === loadedTask) {
                            loadedTask = location.state.task;
                          }
                          let newState = {...location.state};
                          delete newState.task;
                          window.history.replaceState(newState, document.title);
                      } else {
                          console.info(`Loading task ${loadedCompletedTask.taskId}`);
                          loadedTask = await Database.retrieveTask(loadedCompletedTask.taskId, item);
                          if (null === loadedTask) { 
                              throw new Error('Task not found for logbook entry'); 
                          }
                          console.info(`Loaded task ${loadedCompletedTask.taskId}, ${loadedTask && loadedTask.name}`);
                      }
                    }
                    checkMounted();

                    // Now set the completed task and task. I set both at once so UI updates both pieces at once.
                    setCompletedTask(loadedCompletedTask);
                    setTask(loadedTask);
                    break;
                case pageTasks:
                    switch (pageItemId) {
                    case bucketNameOverdue:
                        setTaskBucket(TaskBuckets.taskBuckets(item)[0]);
                        break;
                    case bucketNameSoon:
                        setTaskBucket(TaskBuckets.taskBuckets(item)[1]);
                        break;
                    case bucketNameAll:
                        setTaskBucket(TaskBuckets.taskBuckets(item)[2]);
                        break;
                    default:
                        throw(new Error(`Unknown task bucket ${pageItemId}`));
                    }
                    break;
                default:
                    throw(new Error(`Unknown page ${page}`));
                }
            } catch (err) {
                if (!mounted) {
                    console.error(`Wasted load for ${page}, ${itemId}, ${pageItemId}`);
                    return;
                }
                if (showError) { showError(err); }
                return;
            }

            // Task/logbook/pickTask have more loading to do in the useEffect below.
            // Others, hide the loading indicator.
            if (hideLoading && page !== pageTask && page !== pageLogbook && page !== pageLogbookPickTask) {
                hideLoading(); 
            }
        }

        fetch();

        return () => { mounted = false; }
    }, [location, showLoading, hideLoading, showError, itemId, page, pageItemId]);


    // When the item is loaded, if on the task page, fetch the task. I do this after so the UI can
    // show the item while the task loads.
    useEffect( () => {
        let mounted = true;

        async function fetchTask() {
            try {
                // Load data from state if its is there.
                if (location.state && location.state.task && location.state.task.id === pageItemId) {
                    setTask(location.state.task);

                    // I only want to pull out of state once, so if the user goes back or reloads,
                    // I load from the database. Otherwise can see stale date.
                    let newState = {...location.state}
                    delete newState.task;
                    window.history.replaceState(newState, document.title)
                } else {
                    // Load the task
                    console.info(`Loading task ${pageItemId}`);

                    if (showLoading) { showLoading(); }
                    let task = await Database.retrieveTask(pageItemId, item);
                    if (null === task) { 
                        throw new Error('Task not found'); 
                    }

                    console.info(`Loaded task ${pageItemId}, ${task && task.name}`);

                    if (!mounted) {
                        console.error(`Wasted load of task ${pageItemId}`);
                        return;
                    }
                    setTask(task);
                }
                if (hideLoading) { hideLoading(); }
            } catch (err) {
                if (showError) { showError(err); }
                return;                
            }
        }

        async function fetchTasks() {
            try {
                console.log(`Loading tasks for ${item.id}`);

                if (showLoading) { showLoading(); }
                let tasks = await Database.listTasks(item);

                if (!mounted) {
                    console.log('Unnecessary listTasks');
                    return;
                }

                console.log(`Loaded task list for itemId ${item.id}`);

                setTasks(tasks);
                if (hideLoading) { hideLoading(); }
            } catch (err) {
                if (!mounted) { 
                    return;
                }
                showError(err);
            }
        }

        async function fetchLogbook() {
            try {                
                // Load the entries
                console.info(`Loading logbook entries for ${item.id}`);

                if (showLoading) { showLoading(); }
                let entries = await Database.listCompletedTasks(item.id);
                if (null === entries) { 
                    throw new Error('Logbook not found'); 
                }

                console.info(`Loaded logbookEntries for ${item.id}, ${item && item.name}, {entries.count} loaded`);

                if (!mounted) {
                    console.error(`Wasted load of logbookEntries ${item.id}`);
                    return;
                }
                setEntries(entries);
                if (hideLoading) { hideLoading(); }
            } catch (err) {
                if (showError) { showError(err); }
                return;                
            }
        }

        // After item is loaded
        if (item) {
            // If on the task/logbookEntryFromTask page, get the task. On the logbook page, the entries.
            if (page === pageTask) {
                fetchTask();
            } else if (page === pageLogbook) {
                fetchLogbook();
            } else if (page === pageLogbookPickTask) {
                fetchTasks();
            }
        }

        return () => { mounted = false; }
    }, [item, location, pageItemId, showLoading, showError, hideLoading, page]);

    const navigate = useNavigate();

    // TODO: I added stateItems for LogbookEntryFromTask, should I use elsewhere or do differently?
    const navigateTo = useCallback( (page, pageItemId, back = null, stateItems = {}) => {
        function findCompletedTask(id) {
            if (page === pageLogbookEntry) {
                return (
                    entries && entries.find((entry) => (entry.id === pageItemId))
                    ) ||
                    item.completedTasks.items.find((entry) => (entry.id === pageItemId));
            } else if (page === pageLogbookEntryFromTask) {
                if (stateItems.completedTask && stateItems.completedTask.id === pageItemId) {
                  return stateItems.completedTask;
                } else if (completedTask && completedTask.id === pageItemId) {
                  return completedTask;
                }
            }
        }

        function findTask(id) {
            if (page === pageTask) {
                return item.tasks.items.find( (entry) => (entry.id === id) );
            } else if (page === pageLogbookEntryFromTask) {
                if (stateItems.task) {
                  return stateItems.task;
                }
            }
        }

        var url = '';
        // var back = [];

        switch (page) {
        case pageItem:
            url = urlForItem(itemId);
            break;
        case pageEmailItem:
            url = urlForEmailItem(itemId);
            break;
        case pageTask:
            url = urlForTaskId(itemId, pageItemId);
            break;
        case pageLogbook:
            url = urlForLogbook(itemId);
            break;
        case pageLogbookPickTask:
            url = urlForLogbookPickTask(itemId);
            break;
        case pageTasks:
            url = urlForTasks(itemId, pageItemId);
            break;
        case pageLogbookEntry:
            url = urlForLogbookEntry(itemId, pageItemId);
            break;
        case pageLogbookEntryFromTask:
            url = urlForLogbookEntryFromTask(itemId, pageItemId);
            break;
        default:
            throw(new Error(`Unknown page: ${page}`));
        }

        // Completed task can come from state or from the item or logbook entries...
        let comp = findCompletedTask(pageItemId);
        let task = findTask(pageItemId);

        navigate(url, { state: {
            item: item,
            task: task,
            completedTask: comp,
            back: back
        }});
    }, [item, itemId, navigate, entries, completedTask]);


    const Breadcrumbs = useCallback( () => {
        if (!item) {
            return null;
        }

        // On sub-pages of the item, show it in the breadcrumbs. Not I use url, rather
        // than page/itemId in the crumb, as I don't want to navigate to the cached
        // item -- I want to reload it each time.
        let extras = []
        if (page !== pageItem) {
            extras = [
                { 
                    name: (item && item.name) || 'Item', 
                    url: urlForItem(itemId)
                }
            ];
        }
        if ((page === pageLogbookEntry) || 
            (page === pageLogbookPickTask) || 
            (page === pageLogbookEntryFromTask)) 
        {
            extras.push({
                name: 'Logbook',
                url: urlForLogbook(itemId)
            });
        }

        if (location.state && location.state.back) {
            extras.push(location.state.back);
        }

        // TODO: Equipment page(s) nested under item.

        return (
            <ItemBreadcrumbs item={item} extras={extras} navigateTo={navigateTo}/>
        );
    }, [item, itemId, page, navigateTo, location]);


    return [{
        item: item,
        tasks: tasks,
        setItem: setItem,
        navigateTo: navigateTo,
        task: task,
        completedTask: completedTask,
        setCompletedTask: setCompletedTask,
        taskBucket: taskBucket,
        entries: entries,
        setEntries: setEntries,
        Breadcrumbs: Breadcrumbs, 
    }];
};

export default useSharedItemPageState;