// Home page

import { PrimaryText, Ruler } from '../components';

export const ItemsHelp = () => (
  <PrimaryText>
    <div>
      <b>Items</b> can be anything, such as a <b>House</b>, <b>Boat</b>, <b>Car</b>, <b>Airplane</b>, or <b>Project</b>.
    </div>

    <Ruler/>
    <div>An <b>Item</b> has <b><u>Tasks</u></b> you can track. For example:</div>

    <div><b>Cars</b> have <b><u>Oil Changes</u></b> or <b><u>Brakes</u></b> due at some mileage.</div>
    <div><b>Houses</b> need <b><u>Smoke Detector Batteries</u></b> changed on some date.</div>

    <Ruler/>
  </PrimaryText>
);
