// props.equipment is [Item]
// props.heading is a message to put in a heading at top.
// props.addEquipment is a function that adds a new piece equipment to the parent.

import { useNavigate } from "react-router-dom";
import { InfoCard, useStatusMessage } from '.';
import Button from 'react-bootstrap/Button';
import * as Urls from '../fleet-shared/Urls.mjs';

function EquipmentList(props) {
  const navigate = useNavigate();

  // Loading/error UI
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  async function handleAdd() {
    try {
        showText('Adding');
        await props.addEquipment();
        hideStatus();
    } catch (err) {
        showError(err);
    }
  }

  function AddButton() {
    return (
      <div className="text-center center">
        <Button variant="primary" onClick={handleAdd}>
          Add Equipment
        </Button>
        <StatusMessage/>
      </div>
    );
  }

  return (
    <InfoCard 
      header={props.heading}
      columnInfo={[
        {name: 'Name', key: 'name'},
      ]}
      data={props.equipment}
      onClickRow={(item) => { navigate(Urls.urlForItem(item.id)) }}
      footer={AddButton()}
      noTitle="true"
    />
  )
}

export default EquipmentList

