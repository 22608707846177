// Details on one logbook entry. ID comes from a parameter in the URL or data can be 
// passed in state from prior page.
//
// If in URL, loads from database and puts in location.state.
// In state I have:
//    state.task
//    state.item
//    state.back : optional [Extras] for ItemBreadcrumbs
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useStatusMessage, Attachments, Confirm, DataCard, EditLogbookEntry, CardHeader } from '../../components';
import * as Database from '../../utilities/Database.mjs';
import * as Urls from '../../fleet-shared/Urls.mjs';
import * as Common from '../../fleet-shared/Common.mjs';
import { completedTaskFields } from '../../fleet-shared/CompletedTaskFields.mjs';
import { useSharedItemPageState, pageLogbookEntry, pageItem } from '../item/useSharedItemPageState';
import { displayDate } from '../../fleet-shared/Common';

const LogbookEntry = () => {
    // Passed on URL:
    const { itemId, entryId } = useParams();

    // Status message
    const [StatusMessage, {showText, showError, showLoading, hideStatus }] = useStatusMessage();

    // Confirm delete
    const [showConfirm, setShowConfirm] = useState(false);

    // Show edit modal
    const [showEditTask, setShowEditTask] = useState(false);

    // Page data/state
    const [{ item, Breadcrumbs, completedTask, setCompletedTask, navigateTo }] = useSharedItemPageState({
        page: pageLogbookEntry, 
        itemId: itemId,
        pageItemId: entryId,
        showLoading: showLoading, 
        hideLoading: hideStatus, 
        showError: showError
    });


  // Confirm delete, then delete item.
  function deleteTapped() {
    setShowConfirm(true);
  }

  // User confirmed they want to delete this item.
  async function onConfirmDelete() {
    try {
      // show deleting
      showText('Deleting...');
      setShowConfirm(false);

      // Delete the item and all its children:
      await Database.deleteCompletedTask(completedTask.id);

      // Update the model
      var tasks = [...item.completedTasks.items];
      tasks = tasks.filter((t) => t.id !== completedTask.id);
      item.completedTasks.items = tasks;

      // No need for status.
      hideStatus();

      // TODO: Send in state?
      // Go to parent, as this guy is gone... Either sent in state in back, or
      // go to Item.
      // if (location.state && location.state.back) {
      //   url = location.state.back.url;
      // }

      navigateTo(pageItem, itemId);
    } catch (err) {
      setShowConfirm(false);
      showError(err);
    }
  }


  function editTapped() {
    setShowEditTask(true);
  }

  // TODO: Add email logbook entryl
  // function emailTapped() {
  //   console.log('email tapped');
  // }


  // Text to display for a task's field. Translates things like itemCounterId to name.
  const TaskText = ({field}) => {
    if (field.key === 'itemCounterId') {
      return (
        <div>
          {Common.counterName(item, completedTask[field.key])}
        </div>
      );
    } else if (field.type === 'date') {
      return (
        <div>
          {displayDate(completedTask[field.key])}
        </div>
      );
    } else {
      return (
        <div>
          {completedTask[field.key]}
        </div>
      );
    }
  }

  // One field in a task.
  const TaskItem = ({field}) => {
    return (
      <td align="left" className="col-6 entered-data">
        <TaskText field={field}/>
      </td>
    );
  }


  // Table of tasks.
  const TaskTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-hover text-nowrap table-striped">
          <tbody>
              {
              completedTaskFields.map((field) => (
                <tr key={field.name} >
                  <td align="right" className="col-6">{field.name}</td>
                  <TaskItem field={field}/>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }

  // Passed to EditTask.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedTask = useCallback(async (task) => {
    // Set ID's for database.
    task.id = completedTask.id;
    task.itemId = itemId;

    // Update the database, can throw.
    await Database.updateCompletedTask(task, item);

    // And UI.
    setCompletedTask(task);
    hideStatus();
    setShowEditTask(false);
  }, [item, completedTask, itemId, hideStatus, setCompletedTask]);


  // TODO: Links to itemId, itemCounterId, taskId?

  return (
    <div>
      <Breadcrumbs />
      <StatusMessage />

      {completedTask && (
        <div>
          <DataCard
            header=<CardHeader 
              text={completedTask.name} 
              deleteTapped={deleteTapped} 
              editTapped={editTapped} 
            /> 
            body=<TaskTable/>
          />

          <Attachments bucket={Urls.urlForLogbookEntryStorage(itemId, completedTask.id)} />

          <EditLogbookEntry
            show={showEditTask}
            onHide={() => setShowEditTask(false)}
            task={completedTask}
            item={item}
            save={saveEditedTask}
          />
        </div>
      )}

      <Confirm
          show={showConfirm}
          onConfirm={onConfirmDelete}
          onHide = {() => setShowConfirm(false)}
          body='Are you sure?'
          confirmText='Confirm Delete'
          title={`Delete`}>
      </Confirm>
    </div>
  );
}

export default LogbookEntry;
