// Logbook for an item

import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { DataCard, useStatusMessage, LogbookEntryMoreDetail, EditLogbookEntry, LogbookHelp, PickLogbookAddType } from '../../components';
import * as Database from '../../utilities/Database.mjs';
import { useSharedItemPageState, pageLogbook } from '../item/useSharedItemPageState';
import { urlForLogbookPickTask } from '../../fleet-shared/Urls.mjs';

const Logbook = () => {
    const { itemId } = useParams();

    const [StatusMessage, {showLoading, showText, showError, hideStatus}] = useStatusMessage();

    const [showEditLogbookEntry, setShowEditLogbookEntry] = useState(false);
    const [editLogbookEntry, setEditLogbookEntry] = useState(null);

    const [showPickLogbook, setShowPickLogbook] = useState(false);

    const navigate = useNavigate();

    // Page data/state
    const [{ item, Breadcrumbs, entries, setEntries, navigateTo }] = useSharedItemPageState({
        page: pageLogbook, 
        itemId: itemId,
        showLoading: showLoading, 
        hideLoading: hideStatus, 
        showError: showError
    });

    // Called when a new task is added to the DB. Shows in UI and starts editing it.
    function onAdded(newTask) {
      let newEntries = [newTask, ...entries];
      setEntries(newEntries);
      setEditLogbookEntry(newTask);
      setShowEditLogbookEntry(true);
    }

    async function handleAdd() {
      await Database.createCompletedTaskWithUI(itemId, showText, showError, hideStatus, onAdded);
    }

    // Passed to EditTask.  When the Save button is pressed, this is called.
    // throws on errors.
    const saveEditedTask = useCallback(async (task) => {
      // Copy ID's into the saved task.
      task.id = editLogbookEntry.id;
      task.itemId = item.id;

      // Update the database, can throw.
      await Database.updateCompletedTask(task, item);

      // And UI.
      var newEntries = [...entries];
      newEntries[0] = task;
      setEntries(newEntries);
    }, [item, editLogbookEntry, entries, setEntries]);

    async function onClickDelete(entry) {
        showText('Deleting');
        await Database.deleteCompletedTask(entry.id);
        let tasks = entries.filter( (task) => { 
            return task.id !== entry.id;
        });
        hideStatus();

        setEntries(tasks);
    }


    // Array of entries.
    const Entries = () => {
        // Don't render if no entries (initial state)
        if (!entries) {
            return null;
        }

        // If no entries, tell the user.
        if (entries.length === 0) {
            return (
                <LogbookHelp />
            );
        }

        // Show each entry.
        return entries.map( (entry) => (
            <LogbookEntryMoreDetail 
                key={entry.id} 
                entry={entry} 
                item={item}
                showDelete={true}
                onDelete={onClickDelete}
                navigateTo={navigateTo}
            />
        ));
    }

    function handleSelectTaskForLogbook() {
        // Navigate to logbook/picktask
        navigate(urlForLogbookPickTask(item.id));
    }

    return (
        <div className='text-center'>
            { (item) &&  Breadcrumbs()}
            {StatusMessage()}

            <DataCard
                header='Logbook'
                body={<Entries/>}
            />

            <EditLogbookEntry
              show={showEditLogbookEntry}
              onHide={() => setShowEditLogbookEntry(false)}
              task={editLogbookEntry}
              item={item}
              save={saveEditedTask}
            />

            <Button className='m-2' variant='primary' onClick={() => setShowPickLogbook(true) }>
              Add Entry
            </Button>

            <PickLogbookAddType 
                show={showPickLogbook}
                onHide={() => setShowPickLogbook(false)}
                onSelectTask={() => handleSelectTaskForLogbook()}
                onAddAdHoc={handleAdd}
            />


        </div>
    )
}

export default Logbook;