// Business Logic for defining the various "buckets" of upcoming tasks.
// Like "All", "Overdue", and "Upcoming".
//
// Routines add a new property to a Task: bucketName.
//

import * as Common from './Common.mjs';
import * as Urls from './Urls.mjs';

// Lookup up the given counterId in this item's ItemCounter, and return it's counter.
// Returns -1 if not found.
export function counterFromId(item, id) {
  let itemCounter = Common.itemCounterFromId(item, id);
  if (itemCounter) {
      return itemCounter.counter;
  }
  return -1;
}

function counterFromCounters(counters, id) {
  let c = counters[id];
  if (c) {
    return c.counter;
  }
  return -1;
}


// Look at a task and return true if it comes due within a given counter or date range.
function shouldAdd(task, counterMap, range) {
  // All tasks? Just add...
  if (range.allTasks) {
    return true;
  }

  const greaterThanCounterRemaining = range.gtCounter;
  const lessThanOrEqualCounterRemaining = range.lteCounter;
  const greaterThanDate = range.gtDate;
  const lessThanOrEqualDate = range.lteDate;
  const dueCounter = task.dueCounter;
  const dueDate = Common.dateStringToDate(task.dueDate);

  // Not all items are due based on counter. Skip if this one isn't.
  if (null !== dueCounter) {
    // and compare its counter to the counter based on.
    const counterNow = counterFromCounters(counterMap, task.itemCounterId);
    const counterRemaining = dueCounter - counterNow;

    // If in range, return it.
    if ((counterRemaining > greaterThanCounterRemaining) && (counterRemaining <= lessThanOrEqualCounterRemaining)) {
      return true;
    }
  }

  // Due date?
  if (null !== dueDate) {
    let dueTime = dueDate.getTime();

    if ((dueTime > greaterThanDate.getTime()) && (dueTime <= lessThanOrEqualDate.getTime())) {
      return true;
    }
  }

  return false;
}


export const soonDays = 30;
export const soonHours = 50;
export const nameOverdue = 'Overdue';
export const nameSoon    = `${soonDays} day/${soonHours} hour`;
export const nameAll     = 'All Tasks';

// Given a date, return that date at midnight.
export function dateMidnight(date) {
  return new Date(date.setHours(0,0,0,0));

}
// Builds a date object, sets hours to midnight.
export function todayMidnight() {
  return dateMidnight(new Date());
}


// Returns an array of "ranges". Built at runtime, since need current date/time
function buildRanges() {
  const defaultTasksToShow = 3;
  const today = todayMidnight();
  const soonDate = new Date(today.getTime() + soonDays * 1000 * 60 * 60 * 24);

  return [
    { 
      noDuplicate: true,  
      allTasks: false,
      name: nameOverdue,
      icon: "fa-exclamation-circle",
      color: "text-danger",
      numTasksToShow: defaultTasksToShow,
      gtCounter: -999999, 
      lteCounter: 0, 
      gtDate: Common.minDate, 
      lteDate: today,
      tasksUrl: Urls.bucketNameOverdue
    },
    { 
      noDuplicate: true,  
      allTasks: false,
      name: nameSoon, 
      icon: "fa-hourglass-half",
      color: "text-warning",
      numTasksToShow: defaultTasksToShow,
      gtCounter: 0,       
      lteCounter: soonHours, 
      gtDate: today,
      lteDate: soonDate,
      tasksUrl: Urls.bucketNameSoon
    },
    { 
      noDuplicate: false,  
      allTasks: true,
      name: nameAll, 
      icon: "fa-info",
      color: "text-primary",
      numTasksToShow: defaultTasksToShow,
      gtCounter: -999999,
      lteCounter: 999999, 
      gtDate: Common.minDate,
      lteDate: Common.maxDate,
      tasksUrl: Urls.bucketNameAll
    }
  ];
}



// Walk thru the list of tasks and put them in to buckets to show.
function getUpcomingTasks(tasks, counterMap) {
  const ranges = buildRanges();

  // Start with a bunch of empty ranges
  var rangeTasks = ranges.map( (range) => {
    return {
      name: range.name, 
      icon: range.icon, 
      color: range.color, 
      numTasksToShow: 
      range.numTasksToShow, 
      tasksUrl: range.tasksUrl,
      tasks: []
    };
  });

  // For every task, look at the counters/date it is due.
  for (var i=0; i < tasks.items.length; i++) {
    const task = tasks.items[i];

    // Task hasn't been added anywhere yet.
    var didAdd = false;

    // Now go thru the various range buckets and add the task where in to each
    // range it belongs to.
    for (var j=0; j < ranges.length; j++) {
      let range = ranges[j];

      // If this range doesn't allow duplicates, and the task has already been added,
      // move on.
      if (range.noDuplicate && didAdd) {
        continue;
      }

      // Add to this bucket if it's the all tasks, or the task is due in the range.
      let add = shouldAdd(task, counterMap, range);

      // If added to a range (except all-tasks), mark the task as in the bucket. 
      if (add && !range.allTasks) {
        task.bucketName = range.name;
      }

      // Item due? Add to the range tasks and remember it was added.
      if (add) {
        rangeTasks[j].tasks.push(task);
        didAdd = true;
      }
    }
  }
  return rangeTasks;
}


// Given an array of counters, make a counterMap.
export function createConterMapFromCounters(counters) {
  // Create a map of counters id -> value.
  let counterMap = {};

  for (var i=0; i < counters.length; i++) {
    counterMap[counters[i].id] = counters[i];
  }

  return counterMap;
  
}


// Given an item, create a map of counter ID -> Counter.
export function createCounterMapFromItem(item) {
  return createConterMapFromCounters(item.itemCounter.items);
}



// Returns an array of objects:
//    name: Name of bucket (Like "Overdue"), 
//    icon: Icon to use for bucket (like an exclamation point).
//    color: Color to use to display tasks (like text-danger).
//    numTasksToShow: # of tasks from this bucket to show by default.
//    tasksUrl: The last part of the .../tasks/ URL, used by Tasks.jsx.
//    tasks: []  Array of tasks in this bucket.
// The tasks are also modified to add a property "bucketName" that has
// the name of the first bucket the task belongs to.
export function taskBuckets(item) {
  if ((null === item) || (null === item.tasks) || (null === item.tasks.items)) {
    // No tasks, no buckets...
    return [];
  }

  let counterMap = createCounterMapFromItem(item);

  // Sort 'em.
  return getUpcomingTasks(item.tasks, counterMap);

}


// Given a status text, return a dictionary for the StatusBadge component.
export function taskStatusToBadge(status) {
  switch (status) {
  case nameAll:
    return {name: 'All Tasks',   style: 'badge-success'};
  case nameOverdue:
    return {name: 'Overdue',     style: 'badge-danger'};
  case nameSoon:
    return {name: 'Soon',        style: 'badge-warning'};
  default:
    return {name: 'Not Due',     style: 'badge-primary'};
  }
}

// Given a task and an array of ranges and counterMap, update the task's bucketName.
export function updateTaskBucketNameRangesCounterMap(task, ranges, counterMap) {
  // Now go thru the various range buckets and see if the task belongs to it.
  for (var j=0; j < ranges.length; j++) {
    if (shouldAdd(task, counterMap, ranges[j])) {
      // Found the bucket, update the task's bucketName.
      task.bucketName = ranges[j].name;
      break;
    }
  }
}

// Update an array of task's bucketName.
export function updateTaskBucketNameInTaskList(tasks, counterMap) {
  const ranges = buildRanges();

  for (var i=0; i < tasks.length; i++) {
    updateTaskBucketNameRangesCounterMap(tasks[i], ranges, counterMap);
  }
}

// Updates a task's bucketName. 
export function updateTaskBucketName(task, counterMap) {
  const ranges = buildRanges();
  updateTaskBucketNameRangesCounterMap(task, ranges, counterMap);
}
