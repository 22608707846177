// Displays a task -- ID comes from a parameter in the URL or data can be passed in state from prior
// page.
//
// If in URL, loads from database and puts in location.state.
// In state I have:
//    state.task
//    state.item
//    state.back : optional [Extras] for ItemBreadcrumbs

import { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStatusMessage, Attachments, Confirm, DataCard, EditTask, StatusBadge } from '../../components';
import { taskFields } from '../../fleet-shared/TaskFields.mjs';
import { deleteTask, updateTask, copyTaskToCompletedTasks } from '../../utilities/Database.mjs';
import * as Urls from '../../fleet-shared/Urls.mjs';
import { displayDate, counterName } from '../../fleet-shared/Common.mjs';
import { useSharedItemPageState, pageTask, pageLogbookEntryFromTask } from '../item/useSharedItemPageState';

const Task = () => {
  // For URL management, state.
  const navigate = useNavigate();

  // Passed on URL:
  const { itemId, taskId } = useParams();

  // State for UI
  // const [back, setBack] = useState(null);

  // Edit Task UI.  When to show it, and what to show.
  const [showEditTask, setShowEditTask] = useState(false);

  // Confirm delete
  const [showConfirm, setShowConfirm] = useState(false);

  // Status message
  const [StatusMessage, {showText, showError, showLoading, hideStatus}] = useStatusMessage();

    // Page data/state
  const [{ item, task, Breadcrumbs, navigateTo }] = useSharedItemPageState({
    page: pageTask, 
    itemId: itemId, 
    pageItemId: taskId,
    showLoading: showLoading, 
    hideLoading: hideStatus, 
    showError: showError
  });


  // Confirm delete, then delete item.
  function deleteTapped() {
    setShowConfirm(true);
  }

  // User confirmed they want to delete this item.
  async function onConfirmDelete() {
    try {
      showText('Deleting...');
      setShowConfirm(false);

      // Delete the item and all its children:
      await deleteTask(task.id);

      // Status is good:
      hideStatus();

      // Go to parent, as this guy is gone... Either sent in state in back, or
      // go to Item.
      let url = Urls.urlForItem(itemId);

      // TODO: Could go back to the Tasks page, ya?
      // if (back) {
      //   url = back.url;
      // }
      navigate(url, { replace: true });
    } catch (err) {
      setShowConfirm(false);
      showError(err);
    }
  }


  function editTapped() {
    setShowEditTask(true);
  }

  async function addToLogbook() {
    showText('Adding...');

    // Add entry to database.
    let newTask = await copyTaskToCompletedTasks(task, item);

    // TODO: Edit entry page, not from task page... With flag to show update task...
    // Navigate to the page to edit the entry.
    navigateTo(
      pageLogbookEntryFromTask, 
      newTask.id, 
      null,
      {
        completedTask: newTask,
        task: task
      }
    );
  }


  const Header = ({name}) => {
    return (
      <div className="row">
        <div className="col-4">
            <span className="d-flex bd-highlight">
                <button className='mx-1 btn btn-success' onClick={addToLogbook}>Add to Logbook</button>
            </span>
        </div>
        <div className="col-4">
          <h5>
            <span className="text-center">
              <strong>{name}</strong>
            </span>
          </h5>
          <StatusBadge status={task.bucketName}/>
        </div>
        <div className="col-4 float-end">
            <span className="d-flex flex-row-reverse bd-highlight">
              <button className='mx-1 btn btn-danger' onClick={deleteTapped}>Delete</button>
              <button className='mx-1 btn btn-primary' onClick={editTapped}>Edit</button>
            </span>
        </div>
      </div>
    );
  }

  // Text to display for a task's field. Translates things like itemCounterId to name.
  const TaskText = ({field}) => {
    if (field.key === 'itemCounterId') {
      return (
        <div>
          {counterName(item, task[field.key])}
        </div>
      );
    } else {
      return (
        <div>
          {(field.type === 'date') ? displayDate(task[field.key]) : task[field.key]}
        </div>
      );
    }
  }

  // One field in a task.
  const TaskItem = ({field}) => {
    return (
      <td align="left" className="col-6 entered-data">
        <TaskText field={field}/>
      </td>
    );
  }

  // Table of tasks.
  const TaskTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-hover text-nowrap table-striped">
          <tbody>
              {
              taskFields.map((field) => (
                <tr key={field.name} >
                  <td align="right" className="col-6">{field.name}</td>
                  <TaskItem field={field}/>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }

  // Passed to EditTask.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedTask = useCallback(async (editedTask) => {
    // Update the database, can throw.
    editedTask.id = task.id;
    editedTask.itemId = itemId;
    await updateTask(editedTask, item);

    // TODO: ???
    // When come back from edit, the task only has items from task. Our state
    // has a task that also has all the item's data. So copy that.
    editedTask.item = item;

    // Update the UI
    // TODO: Does this?
    Object.assign(task, editedTask);
    setShowEditTask(false);
  }, [item, itemId, task]);


  return (
    <div>
      { item && Breadcrumbs() }

      { StatusMessage() } 

      { 
        (task && item) && (
          <div>
            <DataCard
              header={Header(task)} 
              body={TaskTable()}
            />

            <Attachments bucket={Urls.urlForTaskStorage(itemId, task.id)} />

            <EditTask
              show={showEditTask}
              onHide={() => setShowEditTask(false)}
              task={task}
              item={item}
              save={saveEditedTask}
            />

          </div>
        )
      }

      <Confirm
        show={showConfirm}
        onConfirm={onConfirmDelete}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={`Delete`}>
      </Confirm>

    </div>
  )
}

export default Task;

