
  export const taskFields = [
    {name: "Name",             key: "name",             type: "text" },
    {name: "Description",      key: "description",      type: "text" },
    {name: "Notes",            key: "notes",            type: "text" },
    {name: "Due (counter)",    key: "dueCounter",       type: "number" },
    {name: "Counter Based On", key: "itemCounterId",    type: "select" },
    {name: "Due (Date)",       key: "dueDate",          type: "date" },
    {name: "Counter Interval", key: "counterInterval",  type: "number" },
    {name: "Days Interval",    key: "daysInterval",     type: "number" },
    // item: Item @connection(fields: ["itemId"])
  ];


