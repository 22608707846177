// Header navigation for the app. Includes a sign out button, and various lins at top.
// props.user is the signed in user (or null, if none)
// props.signOut is the function to call when sign out button is hit.

import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import * as Urls from '../fleet-shared/Urls.mjs';

const SignedInMenu = ({user, signOut}) => {
  function signOutClicked() {
    signOut();
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-primary dropdown-toggle m-2"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="pe-2 fa-solid fa-user"></i>
        { user.username }
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <Link className='dropdown-item' to={Urls.urlForProfile()}>Profile</Link>
        </li>
        <li>
          <Link className='dropdown-item' to={Urls.urlForChangePassword()}>Change Password</Link>
        </li>
        <li>
          <div className='dropdown-item'>
            <button className="btn btn-danger" type="button" id="signOut" onClick={signOutClicked}>Sign Out</button>
          </div>
        </li>
      </ul>
    </div>
  ); 
};

const SignedOutMenu = () => {
  const navigate = useNavigate();

  function signInClicked() {
    navigate(Urls.urlForAllItems())
  }

  return (
    <div>
      <button className="btn btn-primary" type="button" id="signIn" onClick={signInClicked}>
        <i className="pe-2 fa-solid fa-user"></i>
        Sign In&#47;Up
      </button>
    </div>
  )
};

const ProfileButton = ({user, signOut}) => {
  if (user != null) {
    return (
      <SignedInMenu user={user} signOut={signOut}/>
    );
  } else {
    return (
      <SignedOutMenu/>
    );
  }
}


function Navigation(props) {
  const location = useLocation();

  return (
    <div className="container pt-4">
      <nav className="navbar navbar-expand-lg navbar-light bg-light c">
        <a className="navbar-brand" href="/">
          <img src="/logo192.png" className='m-2' width="32" height="32" alt="Logo"/>
          TaskPogo
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="d-flex justify-content-between collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className={`nav-item  ${
                  location.pathname === "/" ? "active text-primary bg-primary" : "" 
                 }`
               }
            >
              <Link className='nav-link' to='/'>Home</Link>
            </li>

            <li className={`nav-item  ${Urls.isUrlForAllItems(location.pathname) ? "active text-primary bg-primary" : "" }`}>
              <Link className='nav-link' to={Urls.urlForAllItems()}>Items</Link>
            </li>

            <li className={`nav-item  ${Urls.isUrlForSupport(location.pathname) ? "active text-primary bg-primary" : "" }`}>
              <Link className='nav-link' to={Urls.urlForSupport()}>Support</Link>
            </li>
          </ul>

          <ProfileButton user={props.user} signOut={props.signOut}/>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;