// Header for a TaskTable.

export const TaskListHeader = () => {
  return (
    <thead>
      <tr>
        <th key='statusHdg'            scope='col' className='fw-bold'>Status</th>
        <th key='nameHdg'              scope='col' className='fw-bold'>Name</th>
        <th key='descriptionHdg'       scope='col' className='fw-bold'>Description</th>
        <th key='notesHdg'             scope='col' className='fw-bold'>Notes</th>
        <th key='dueCounterHdg'        scope='col' className='fw-bold'>Due (counter)</th>
        <th key='itemCounterIDHdg'     scope='col' className='fw-bold'>Counter Based On</th>
        <th key='dueDateHdg'           scope='col' className='fw-bold'>Due (Date)</th>
        <th key='counterIntervalHdg'   scope='col' className='fw-bold'>Counter Interval</th>
        <th key='daysIntervalHdg'      scope='col' className='fw-bold'>Days Interval</th>
      </tr>
    </thead>
  );    
}
