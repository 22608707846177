// Pick from the item's tasks an entry to be added to the logbook.

import { useParams } from 'react-router-dom';
import { useStatusMessage } from '../../components';
import { useSharedItemPageState, pageLogbookPickTask, pageLogbookEntryFromTask } from '../item/useSharedItemPageState';
import { copyTaskToCompletedTasks } from '../../utilities/Database.mjs';
import { TaskTable } from '../task/TaskTable';

export const LogbookPickTask = () => {
  const { itemId } = useParams();

  const [StatusMessage, {showLoading, showText, showError, hideStatus}] = useStatusMessage();

  // Page data/state
  const [{ item, tasks, Breadcrumbs, navigateTo }] = useSharedItemPageState({
    page: pageLogbookPickTask, 
    itemId: itemId,
    showLoading: showLoading, 
    hideLoading: hideStatus, 
    showError: showError
  });

  async function taskClicked(task) {
    showText('Adding');

    // Add entry to database.
    let newTask = await copyTaskToCompletedTasks(task, item);

    // Navigate to the page to edit the entry.
    // TODO: Edit entry page, not from task page... With flag to show update task...
    // Note that I don't pass in the task, cuz the task doesn't contain "deep" items,
    // like the counter the task is based on (list just gets the top level item, not
    // the things it's linked to).
    // Note that I don't pass the task in, because it sometimes isn't "complete" (doesn't
    // contain counters and such).
    navigateTo(
      pageLogbookEntryFromTask, 
      newTask.id, 
      null, 
      { 
        completedTask: newTask
        // task:task
      }
    );
  }


  return (
    <div className="container pt-2">
      <div className="card">
        <div className="card-header text-center">

          { (item) &&  Breadcrumbs()}

          <StatusMessage/>

          <h3>Choose a Task to add to the Logbook</h3>

          { tasks && <TaskTable
              tasks={tasks}
              item={item}
              taskClicked={(task) => taskClicked(task) }
            />
          }
        </div>
      </div>
    </div>
  );
}

export default LogbookPickTask;
