// A component that renders a single task in a line in a TaskTable 

import { StatusBadge } from '../../components';
import { counterName, displayDate } from '../../fleet-shared/Common.mjs';

export const TaskLine = ({ task, item, taskClicked, children}) => (
  <tr className='entered-data' onClick={() => taskClicked(task) } >
    <td key='status'>
      <StatusBadge status={task.bucketName}/>
    </td>
    <td key='name'>
      {task.name}
    </td>
    <td key='description'>
      {task.description}
    </td>
    <td key='notes'>
      {task.notes}
    </td>
    <td key='dueCounter'>
      {task.dueCounter}
    </td>
    <td key='itemCounterId'>
      { counterName(item, task.itemCounterId) }
    </td>
    <td key='dueDate'>
      {displayDate(task.dueDate)}
    </td>
    <td key='counterInterval'>
      {task.counterInterval}
    </td>
    <td key='daysInterval'>
      {task.daysInterval}
    </td>
    {children}
  </tr>
);