// Shows a card with upcoming tasks in various buckets (overdue, soon, all, etc.). Shows only
// a few from each bucket. When click on a task, calls parent to edit it. If too many tasks to
// show, shows an show-all button and calls parent to show all the tasks if click the button.
//
// props.buckets          - See utilties/TaskBuckets. The various buckts of tasks to display.
// props.onClickTask      - A function to call when a task is clicked on. Takes the task as a parameter.
// props.setTaskBucket    - A function to call to set a bucket of tasks to show (used with props.setShowTaskBucket).
// props.setShowTasksBucket - A function to call to show the task list UI. Used with props.setTaskBucket.
// props.addTask          - A function to call to add a task and present UI to edit it.

import React from 'react';
import TaskSummary from './TaskSummary';
import Button from 'react-bootstrap/Button';
import DataCard from './DataCard';
import { useStatusMessage } from '.';

const taskSummaries = (props) => {
  return props.buckets && props.buckets && props.buckets.map( (bucket, index) => { 
    return <TaskSummary 
      key={index}
      className="col-4" 
      heading={bucket.name}
      bucket={bucket} 
      icon={bucket.icon}
      color={bucket.color}
      onClickTask={props.onClickTask}
      numTasksToShow={bucket.numTasksToShow}
      setShowTaskBucket={props.setShowTaskBucket}
      setTaskBucket={props.setTaskBucket}
    />
  });

}

const UpcomingTasks = (props) => {
    // Loading/error UI
    const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

    async function addTask() {
        try {
            showText('Adding');
            await props.addTask();
            hideStatus();
        } catch (err) {
            showError(err);
        }
    }

    return (
        <DataCard
          header="Upcoming Tasks"
          body={
            <div className="row justify-content-center">
              { taskSummaries(props) }

              <div className="text-center center mb-2">
                <Button variant="primary" onClick={addTask}>
                  Add Task
                </Button>
                {StatusMessage()}
              </div>
            </div>
          }
        />
    )
}

export default UpcomingTasks;
