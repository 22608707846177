// Displays an item, like a vessel, engine, generator, etc -- ID comes from a parameter in the URL.

import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { itemFields } from '../../fleet-shared/ItemFields.mjs';
import { 
  useStatusMessage, 
  LogbookSummary, 
  Attachments,  
  EquipmentInfoUI, 
  EquipmentList, 
  ModalEditForm, 
  EditTask, 
  HourMeter, 
  UpcomingTasks, 
  Confirm } from '../../components';
import * as TaskBuckets from '../../fleet-shared/TaskBuckets.mjs';
import * as Database from '../../utilities/Database.mjs';
import * as Urls from '../../fleet-shared/Urls.mjs';
import { useSharedItemPageState, pageItem, pageTasks, pageTask, pageLogbook, pageEmailItem } from './useSharedItemPageState';

const ItemUI = ( {item, identityId, showText, showError, hideStatus, setItem, navigateTo} ) => {
  // Various status badges:
  const [counterItems, setCounterItems] = useState(null);

  // The task buckets for this item's tasks (see utitlies/TaskBuckets.js)
  const [upcomingTaskBuckets, setUpcomingTaskBuckets] = useState(null);

  // Edit Item UI.  When to show it, and what to show.
  const [showEditItem, setShowEditItem] = useState(false);
  const [editItem, setEditItem] = useState(null);

  // Edit Task UI.  When to show it, and what to show.
  const [showEditTask, setShowEditTask] = useState(false);
  const [editTask, setEditTask] = useState(null);


  // Confirm delete
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();


  // Whenever the item changes, I update the Counter/Upcoming Tasks
  useEffect( () => {
    console.info('updateUI');

    // Update the counter UI.
    if ((null != item) && (null != item.itemCounter)) {
      setCounterItems(item.itemCounter.items);
    } else {
      setCounterItems(null);
    }

    // Update the coming soon tasks UI
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);

    return () => {
      console.log("updateUI unmounted");
    }
  }, [item]);


  // State to show the all tasks pane.
  const [showTaskBucket, setShowTaskBucket] = useState(false);
  const [taskBucket, setTaskBucket] = useState(null);

  // When the item changes, update the UI. 
  useEffect( () => {
    // If showTaskBucket changes to true, goto the tasks page.
    if (showTaskBucket) {
      navigateTo(pageTasks, taskBucket.tasksUrl);
    }
  }, [showTaskBucket, navigateTo, taskBucket]);


  // Bring up the edit UI for this item.
  const editTapped = useCallback(() => {
    setEditItem(item);
    setShowEditItem(true);
  }, [item]);



  // Delete the given counterId
  const deleteCounter = useCallback(async(id) => {
    // This can throw, caller catches and shows error...
    await Database.deleteCounter(id, item.itemCounter.items);
  }, [item]);



  // Passed to EditHours.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveCounter = useCallback(async (counter) => {
    // This can throw, caller catches and shows error...
    await Database.updateCounter(counter, item.itemCounter.items);
  }, [item]);


  // Confirm delete, then delete item.
  const deleteTapped = useCallback(() => {
    setShowConfirm(true);
  }, [setShowConfirm]);


  // User confirmed they want to delete this item.
  const onConfirmDelete = useCallback( async () => {
    showText("Deleting...")

    try {
      setShowConfirm(false);
      // Delete the item and all its children:
      await Database.deleteItem(item);

      // Status is good:
      hideStatus();

      // Go to parent, as this guy is gone...
      if (item.parentId != null) {
        navigate(Urls.urlForItem(item.parentId), { replace: true });
      } else {
        navigate(Urls.urlForAllItems(), {replace: true });;
      }
    } catch (err) {
      setShowConfirm(false);
      showError(err);
    }
  }, [showText, setShowConfirm, hideStatus, showError, item, navigate]);



  // Passed to EditItem.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedItem = useCallback(async (newItem) => {
    // This can throw, caller catches and shows error...
    let savedItem = await Database.saveEditedItem(newItem, item);

    // Editing this item?  Update the UI.
    if (item.id === savedItem.id) {
      Object.assign(item, newItem);
    } else {
      // TODO: Can't equipment have equipment. i.e. should this be recursive?
      // or no -- cuz can only edit a child in current UI, not a child of a child.

      // No, editing some equipment, update UI for that...
      const cItems = item.childItems.items.length;
      for (var i=0; i < cItems; i++) {
        if (item.childItems.items[i].id === newItem.id) {
          item.childItems.items[i] = savedItem;
          break;
        }
      }
    }

    setShowEditItem(false);
  }, [item]);

  
  // Adds new equpment to the item.  Throws on error.  Returns a link to the new equipment.
  const addEquipment = useCallback(async () => {
    // Add item to the database
    let newItem = {
      name: 'New item',
      parentId: item.id
    };

    // This can throw, caller catches and shows error...
    const createdItem = await Database.createItem(newItem);
    item.childItems.items = [...item.childItems.items, createdItem];

    setEditItem(createdItem);
    setShowEditItem(true);

    return createdItem;
  }, [setEditItem, setShowEditItem, item]);


  // Adds a new task to this item. Throws on error.
  const addTask = useCallback(async () => {
    // Add to the database and the model.
    var task = await Database.createTask('New Task', item);

    // Update the model. I copy the item and set it again to force the re-render
    let newItem = {...item};
    newItem.tasks.items.push(task);
    setItem(newItem);

    // Show the edit task UI
    setEditTask(task);
    setShowEditTask(true);
  }, [item, setItem]);


  // Passed to EditTask.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedTask = useCallback(async (task) => {
    task.id = editTask.id;
    task.itemId = item.id;

    // Update the database and model. This can throw, caller catches and shows error...
    await Database.updateTask(task, item, item.tasks.items);

    // Update the coming soon tasks UI
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);

    setShowEditTask(false);
  }, [item, editTask]);


  // Passed to EditTask, deletes the given taskId
  const deleteTask = useCallback(async(id) => {
    // Update the database and item.tasks.items. Can throw, caller catches and shows error.
    await Database.deleteTask(id, item.tasks.items);

    // Update UI
    setShowEditTask(false);
    const buckets = TaskBuckets.taskBuckets(item);
    setUpcomingTaskBuckets(buckets);
  }, [item]);

  // When click on a task, go to the task detail page.
  const onClickTask = useCallback((task) => {
    navigateTo(pageTask, task.id);
  }, [navigateTo]);


  const onAllLogbook = useCallback(() => {
    navigateTo(pageLogbook, item.id);
  }, [navigateTo, item]);

  const onLogbookAdded = useCallback((task) => {
    // Add the new task
    var tasks = [...item.completedTasks.items, task].sort((a,b) => {
      if (a.date < b.date) {
        return -1;
      } else if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    // Sorted by date.

    item.completedTasks.items = tasks;
  }, [item]);

  const onLogbookSaved = useCallback((task) => {
    var tasks = [...item.completedTasks.items];
    tasks = tasks.map((t) => {
      if (t.id === task.id) {
        return task;
      }
      return t;
    });
    item.completedTasks.items = tasks;
  }, [item]);


  const emailTapped = useCallback(() => {
      navigateTo(pageEmailItem);
  }, [navigateTo]);


  return (
    (item != null) &&
    <div className="pb-3">
      <EquipmentInfoUI 
        identityId={identityId}
        item={item} 
        deleteTapped={deleteTapped} 
        editTapped={editTapped}
        emailTapped={emailTapped}
        navigateTo={navigateTo} />

      <HourMeter 
        itemId={item.id} 
        counterItems={counterItems} 
        save={saveCounter} 
        delete={deleteCounter} />

      <UpcomingTasks 
        buckets={upcomingTaskBuckets}
        onClickTask={onClickTask}
        setTaskBucket={setTaskBucket}
        setShowTaskBucket={setShowTaskBucket}
        addTask={addTask}
      />

      <LogbookSummary
        item={item}
        onAll={onAllLogbook}
        onAdded={onLogbookAdded}
        onSaved={onLogbookSaved}
        navigateTo={navigateTo}
      />

      <EquipmentList 
        equipment={ item.childItems.items || [] } 
        heading={ (item.parentId == null) ? "Equipment" : "Parts"} 
        addEquipment={ addEquipment }
      />

      <ModalEditForm
        show={showEditItem}
        fields={itemFields}
        onHide={() => setShowEditItem(false)}
        item={editItem}
        save={saveEditedItem}
      />

      <EditTask
        show={showEditTask}
        onHide={() => setShowEditTask(false)}
        task={editTask}
        item={item}
        save={saveEditedTask}
        delete={deleteTask}
      />

      <Confirm
        show={showConfirm}
        onConfirm={onConfirmDelete}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={`Delete ${item.name}`}>
      </Confirm>
    </div>
  )
}



const Item = ({user}) => {
  // Passed on URL:
  const { itemId } = useParams();

  // Loading/error UI
  const [StatusMessage, {showLoading, showText, showError, hideStatus}] = useStatusMessage();

  // Page data/state
  const [{ item, setItem, Breadcrumbs, navigateTo }] = useSharedItemPageState({
    page: pageItem, 
    itemId: itemId, 
    showLoading: showLoading, 
    hideLoading: hideStatus, 
    showError: showError
  });

  return (
    <div>
      <Breadcrumbs/>
      <StatusMessage/>
      <ItemUI identityId={user ? user.identityId : null} item={item} showText={showText} showError={showError} hideStatus={hideStatus} setItem={setItem} navigateTo={navigateTo}/>
      <Attachments bucket={Urls.urlForItemStorage(itemId)} hideLoading={(item === null)} />
    </div>
  )
}

export default Item;

