// Modal component to edit a task.
//
// props.task is the task to edit.
// props.item is the Item the task belongs to.
// props.save is an async function that is called when save is tapped.
// props.delete is an async function that is called when delete is tapped.
// props.show is used to show/hide the UI.
// props.onHide is a function used to hide the UI.

import { taskFields } from '../fleet-shared/TaskFields.mjs';
import { buildFieldsForItem } from '../fleet-shared/Common.mjs'
import ModalEditForm from './ModalEditForm';

function EditTask(props) {


  async function handleDelete() {
    await props.delete(props.task.id);
  }



  return (
    (props.task != null) && 
    <ModalEditForm
      show={props.show}
      onHide={props.onHide}
      fields={buildFieldsForItem(taskFields, props.item)}
      item={props.task}
      save={props.save}
      delete={handleDelete}
    />
  );
}

export default EditTask;
