// Summary of one entry in the logbook.

import Button from 'react-bootstrap/Button';
import { pageLogbookEntry } from '../pages/item/useSharedItemPageState';
import { displayDate } from '../fleet-shared/Common';


// Single entry
const LogbookEntrySummary = ({entry, item, showDelete, onDelete, navigateTo}) => {
    function onDetails() {
        navigateTo(pageLogbookEntry, entry.id);
    }

    return (
        <div className='container'>
            <div className='row pt-1'>
                <div className='col-2 text-end entered-data'>
                    {displayDate(entry.date)}
                </div>
                <div className='col-3 entered-data text-end'>
                    {entry.name}
                </div>
                {showDelete && (
                    <i className='fa fa-trash col-1' aria-hidden='true' onClick={() => onDelete(entry)}></i>
                )}
                <Button className='col-2' variant='primary' onClick={onDetails}>
                  See Details
                </Button>
            </div>
        </div>
    );
}

export default LogbookEntrySummary;