// At the top of a DataCard, a header with delete/edit/email buttons
export const CardHeader = ( { text, deleteTapped, editTapped, emailTapped } ) => {
  return (
    <div className="row">
      <div className="col-4"/>
      <div className="col-4">
        <h5>
          <span className="text-center">
            <strong>{text}</strong>
          </span>
        </h5>
      </div>
      <div className="col-4 float-end">
          <span className="d-flex flex-row-reverse bd-highlight">
            {deleteTapped && <button className='mx-1 btn btn-danger' onClick={deleteTapped}>Delete</button>}
            {editTapped && <button className='mx-1 btn btn-primary' onClick={editTapped}>Edit</button>}
            {emailTapped && <button className='mx-1 btn btn-secondary' onClick={emailTapped}><i className="fa-regular fa-envelope"></i></button>}
          </span>
      </div>
    </div>
  );
}

export default CardHeader;