// Thumbnail support for an Item.
//
// State:
//  back - Optional Url to go to after successful upload or cancel. Goes to all items if not specified.

import { StorageManager } from '@aws-amplify/ui-react-storage';
import { useState, useEffect, useCallback } from 'react';
import { useStatusMessage, Confirm, Spinner, ErrorText } from '../../components';
import { 
  urlForItemThumbnailUpload,
  urlForItemThumbnailSmall, 
  urlForItemThumbnailMedium, 
  thumbnailPrefix,
  urlForItemStorage,
  ThumbnailSmallSize, 
  ThumbnailMediumSize } from '../../fleet-shared/Urls';
import '@aws-amplify/ui-react/styles.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { storageDelete, storageGet, storageList } from '../../utilities/Database';

import { TaskPogoImageProcessor } from '../../graphql/mutations'; 
import { generateClient } from 'aws-amplify/api';
const client = generateClient();


const NotFoundError = 'NotFound';

// This is React Hook that goes to storage to get a link to a file. 
// The hook returns a URL to the file. When initialized, we don't
// have a file, and URL is null. This goes to Storage to see if the 
// file exists, if so, the URL is set. If there's an error, 
// onStorageGetError is called.
const useStorageURL = ({
  key,
  onStorageGetError
}) => {
  const [url, setURL] = useState(null);

  useEffect(() => {
    async function load() {
      try {
        console.log(`Fetching image key: ${key}`);
        
        // Get the URL, throw if doesn't exist.
        let file = await storageGet(key, true);

        if (ignore) { 
          return; 
        }

        setURL(file.url.toString());
      } catch (err) {
        if (ignore) { 
          return; 
        }

        if (onStorageGetError) {
          onStorageGetError(err);
        }
      }
    }

    // We are async, if we are destroyed before return, ignore is set.
    let ignore = false;

    // Do the async load.
    load();
    
    return () => {
      ignore = true;
    };
  }, [key, onStorageGetError]);

  return [url, setURL];
};


// Modal component for uploading a thumbnail for an item.
export const ModalThumbnailUpload = ({ 
  show,       // Show / Hide modal UI.
  onHide,     // Called to hide.
  itemId,     // ItemId showing the UI for.
  didUpload   // Callback on successful file upload didUpload({key, name}).
}) => {
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  const uploadError = useCallback((error, file) => {
    console.log(`Upload error ${JSON.stringify(file)}, ${JSON.stringify(error)}`)
    showError(error);
  }, [showError]);

  const uploadStarted = useCallback((file) => {
    console.log(`Upload started ${JSON.stringify(file)}`)
    // console.log(`uploadedStarted: ${JSON.stringify(file)}`);

    showText('Uploading image');
  }, [showText]);

  // Dismiss the UI.
  const dismiss = useCallback(() => {
    hideStatus();
    onHide();
  }, [hideStatus, onHide]);

  // Called by StorageManager when upload is successful.
  const uploadSuccess = useCallback((file) => {
    console.log(`Uploaded ${JSON.stringify(file)}`)
    if (didUpload) {
      didUpload(file);
    }

    dismiss();
  }, [didUpload, dismiss]);

  // Called by StorageManager when uploading a file. Add the bucket and "thumbnail-" in front of filename.
  const processFile = useCallback((keyFile) => {
    console.log(`processFile ${keyFile}`);
    
    return { 
      key: urlForItemThumbnailUpload(itemId, keyFile.key),
      file: keyFile.file
    }
  }, [itemId]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Item Image</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='container text-center'>
          <StatusMessage/>

          <StorageManager
            acceptedFileTypes={['image/*']}
            accessLevel='private'
            maxFileCount={1}
            maxFileSize={10000000}
            onUploadStart={uploadStarted}
            onUploadSuccess={(key) => { uploadSuccess(key); }}
            onUploadError={uploadError}
            processFile={processFile}
            isResumable
          />

          <Button variant="danger" className="mx-2 m-2" onClick={dismiss}>
            Cancel
          </Button>
         </div>
      </Modal.Body>
    </Modal>

  );
}


// Component shown when there is no Thumbnail.
const ThumbnailFallback = ({ size, itemId, setShowUpload, errorName }) => {
  function uploadImage() {
    setShowUpload(true);
  }

  if (errorName === '') {
    return null;
  }


  // Showing a true thumbnail? Just show a generic image.
  if (size !== ThumbnailMedium) {
    return <i className="fa-solid fa-image"></i>
  } 


  // Otherwise show UI to let the user upload.
  return <div className='container'>
    { errorName !== NotFoundError ? <div className='row'><ErrorText text={errorName}/></div> : null}
    <div className='row'>
      <Button variant="primary" onClick={uploadImage}>
          Upload Image
      </Button>
    </div>
  </div>
}



// If have a thumbnail, this is the component that renders.
const ThumbnailImage = ( { size, url, onEdit, onDelete, errorName } ) => {
  // Shows the image at the given URL. And if not a small thumbnail buttons
  // to edit/delete it.

  if (errorName !== '') { 
    return null;
  }


  if (url === null) {
    return <div className='text-center'><Spinner/></div>
  } 


  // TODO: Click through to original (need to do a list to get the key, tho).
  if (size !== ThumbnailMedium) {
    return <img src={url} alt='' className='thumbnail' width={ThumbnailSmallSize} height={ThumbnailSmallSize}/>
  } else {
    return (
        <>
        <img src={url} alt='' className='thumbnail-medium' width={ThumbnailMediumSize} height={ThumbnailMediumSize}/>
        <div className='thumbnail-edit d-flex align-items-center'>
          <Button variant='info' className='mx-2 m-2' size='sm' onClick={onEdit}>
            Edit
          </Button>
          <i className='fa fa-trash col-1' aria-hidden='true' onClick={onDelete}></i>
        </div>
      </>
    );
  }
}

export const ThumbnailMedium = "medium";
export const ThumbnailSmall = "small";

// Component to display a thumbnail for an item or some fallback UI.
export const Thumbnail = ( { identityId, itemId, size, goBack } ) => {
  const [errorName, setErrorName] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [key] = useState(size === ThumbnailMedium ? urlForItemThumbnailMedium(itemId) : urlForItemThumbnailSmall(itemId));

  const handleError = useCallback((error) => {
    setErrorName(error.name);
  }, [setErrorName]);

  const [url, setURL] = useStorageURL({
      key: key,
      onStorageGetError: handleError,
  });

  // Edit hit, show the upload UI.
  function onEditThumbnail() {
    setShowUpload(true);
  }

  // User hit the delete button, confirm they want to.
  function onDeleteThumbnail() {
    setShowConfirm(true);
  }

  // User confirmed delete, go do it (and show UI while).
  async function onDeleteThumbnailConfirmed({ showText, showError, hideStatus }) {
    try {
      // Show UI that the file is being deleted.
      showText('Deleting...');

      // Delete the thumbnail files.
      // I need the filename for the uploaded file, so first list...
      // TODO: Do in parallel.
      let files = await storageList(urlForItemStorage(itemId));
      for (var i=0; i < files.length; i++) {
        if (files[i].key.includes(thumbnailPrefix)) {
          await storageDelete(files[i].key);
        }
      }

      // Update the UI -- no file, no confirm UI, show UI for file not found.
      setErrorName(NotFoundError);
      setURL(null);
      hideStatus();
      setShowConfirm(false);
    } catch (err) {
      showError(err);
    }
  }


  // Called when uploaded a thumbnail. Ask server to create the various thumbnails then show the medium one.
  async function thumbnailUploaded(file) {
    try {
      setErrorName('Finishing upload');

      await client.graphql({ 
        query: TaskPogoImageProcessor, 
        variables: {
          userId: identityId,
          itemId: itemId,
          filename: file.key.slice(itemId.length+1)
        }
      });

      // Get the URL, throw if doesn't exist.
      let f = await storageGet(key, true);
      setURL(f.url.toString());
      setErrorName('');
    } catch (err) {
      setErrorName(err.name || err);
    }

  }


  var imageClass = 'thumbnail';
  if (size === ThumbnailMedium) {
    imageClass = 'thumbnail-medium';
  }

  // If no thumnail, shows ThumbnailFallback,
  // otherwise a StorageImage of the thumbnail.

  return (
    <div className={imageClass}>
      <ThumbnailFallback 
        size={size} 
        itemId={itemId} 
        setShowUpload={setShowUpload}
        errorName={errorName}
      />

      <ThumbnailImage 
        size={size} 
        url={url}
        onEdit={onEditThumbnail}
        onDelete={onDeleteThumbnail}
        errorName={errorName}
      />

      <ModalThumbnailUpload
        show={showUpload}
        onHide={()=>setShowUpload(false)}
        itemId={itemId}
        didUpload={thumbnailUploaded}
      />

      <Confirm
        show={showConfirm}
        onConfirm={onDeleteThumbnailConfirmed}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={'Delete image'}>
      </Confirm>

    </div>
  );
}

