// Page to create a logbook entry (CompletedTask) from a task.
// User can elect to modify the task on creating the entry.

import { useParams } from 'react-router-dom';
import { useStatusMessage } from '../../components';
import { useSharedItemPageState, pageLogbookEntryFromTask } from '../item/useSharedItemPageState';
import { LogbookEditEntry } from './LogbookEditEntry';
import { LogbookUpdateTask  /*, 
          UpdateTaskFromDue, 
          UpdateTaskFromNow,
          UpdateTaskNothing, 
          UpdateTaskDelete */} from './LogbookUpdateTask';
import { updateCompletedTask } from '../../utilities/Database';

export const LogbookEntryFromTask = () => {
  // Passed in URL
  const { itemId } = useParams();
  const { taskId } = useParams();

  // For showing loading status.
  const [StatusMessage, {showLoading, showError, hideStatus}] = useStatusMessage();

  // Page data/state
  const [{ item, Breadcrumbs, completedTask, task }] = useSharedItemPageState({
      page: pageLogbookEntryFromTask, 
      itemId: itemId,
      pageItemId: taskId,
      showLoading: showLoading, 
      hideLoading: hideStatus, 
      showError: showError
  });

  // Saves edited logbook entry. Throws with errors.
  async function handleSaveLogbookEntry(entry) {
    if (!entry.name || entry.name.length === 0) {
      throw new Error('Please enter a name.');
    }
    // Set id's
    entry.id = completedTask.id;
    entry.itemId = itemId;
    entry.taskId = taskId;

    // TODO: What if they want to change?
    entry.itemCounterId = completedTask.itemCounterId;

    await updateCompletedTask(entry);
  }

  function handleDelete() {
    // If there are attachments and this was the create, delete them...
  }

  if ((null === item) || (null === completedTask) || (null === task)) {
    return (
      <StatusMessage />
    );
  }

  return (
      <div className='text-center'>
          <Breadcrumbs />
          <StatusMessage />
          <LogbookUpdateTask task={task} item={item} />
          <LogbookEditEntry 
            entry={completedTask}
            item={item}
            handleSave={handleSaveLogbookEntry}
            handleDelete={handleDelete}
          />
      </div>
  );
}

export default LogbookEntryFromTask;
