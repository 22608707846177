// Modal component to edit a logbook entry.
//
// props.task is the task to edit.
// props.item is the Item the task belongs to.
// props.save is an async function that is called when save is tapped.
// props.delete is an async function that is called when delete is tapped.
// props.show is used to show/hide the UI.
// props.onHide is a function used to hide the UI.

import { completedTaskFields } from '../fleet-shared/CompletedTaskFields.mjs';
import ModalEditForm from './ModalEditForm';

function EditLogbookEntry(props) {
    // TODO: Copied form EditTask...

  // One of the task fields is the "Counter Based On".
  // This is a "select" which lets the user pick from
  // a list. The list is built at runtime with the
  // item's counterArray.
  function buildTaskFields() {
    let fields = completedTaskFields;

    for (var i=0; i < fields.length; i++) {
      // Map the counter ID's to names.
      if ("itemCounterId" === fields[i].key) {
        let field = fields[i];

        field.selects = props.item.itemCounter.items.map( (counterItem, index) => {
          // index + 1 to make room for no-counter, set below.
          return {"id" : counterItem.id, "index" : index+1, "name" : counterItem.name }
        });

        // Allow selecting no-counter.
        field.selects.unshift({"id" : null, "index": 0, "name" : "(None)"})
        fields[i] = field;

        // Just the one to do...
        break;
      }
    }

    return fields;
  }

  async function handleDelete() {
    await props.delete(props.task.id);
  }



  return (
    (props.task != null) && 
    <ModalEditForm
      show={props.show}
      onHide={props.onHide}
      fields={buildTaskFields()}
      item={props.task}
      save={props.save}
      delete={handleDelete}
    />
  );
}

export default EditLogbookEntry;
