// Summary of one entry in the logbook.

import Button from 'react-bootstrap/Button';
import { pageLogbookEntry } from '../pages/item/useSharedItemPageState';
import { displayDate } from '../fleet-shared/Common';


// Single entry
const LogbookEntryMoreDetail = ({entry, item, showDelete, onDelete, navigateTo}) => {

    function onDetails() {
        navigateTo(pageLogbookEntry, entry.id);
    }

    return (
        <div className='container'>
            <div className='row pt-1'>
                <div className='col-2 text-end entered-data'>
                    {displayDate(entry.date)}
                </div>
                <div className='col-2'>
                    {entry.counter}
                    counter
                </div>
                <div className='col-3 entered-data text-end'>
                    {entry.name}
                </div>
                {showDelete && (
                    <i className='fa fa-trash col-1' aria-hidden='true' onClick={() => onDelete(entry)}></i>
                )}
                <Button className='col-2' variant='primary' onClick={onDetails}>
                  See Details
                </Button>
            </div>

            <div className='row pt-1'>
                <div className='col-2 text-end'>
                    Description:
                </div>
                <div className='col-11 entered-data text-begin'>
                    {entry.description}
                </div>
            </div>

            <div className='row pt-1'>
                <div className='col-2 text-end'>
                    Notes:
                </div>
                <div className='col-4 entered-data text-end'>
                    {entry.notes}
                </div>
                <div className='col-2 text-end'>
                    Notes from Task:
                </div>
                <div className='col-4 entered-data text-end'>
                    {entry.taskNotes}
                </div>
            </div>

            <div className='row pt-1'>
                <div className='col-2'>
                    Was due counter:
                </div>
                <div className='col-2 entered-data'>
                    {entry.dueCounter}
                </div>
                <div className='col-2'>
                    every
                </div>
                <div className='col-2 entered-data'>
                    {entry.counterInterval}
                </div>
            </div>
            <div className='row pt-1'>
                <div className='col-2'>
                    Was due date:
                </div>
                <div className='col-2 entered-data'>
                    {displayDate(entry.dueDate)}
                </div>
                <div className='col-2'>                    
                    every
                </div>
                <div className='col-2 entered-data'>
                    {entry.daysInterval}
                </div>
            </div>
            <hr className="hr" />
        </div>
    );
}

export default LogbookEntryMoreDetail;