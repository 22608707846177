// A component that renders a list of tasks.

import { TaskListHeader} from './TaskListHeader';
import { TaskLine } from './TaskLine';

export const TaskTable = ({ tasks, item, taskClicked }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover text-nowrap table-striped">
        <TaskListHeader />
        <tbody>
        {
          tasks.map((task, index) => (
            <TaskLine 
              task={task} 
              item={item}
              key={index} 
              taskClicked={taskClicked}
            />
          ))
        }
        </tbody>
      </table>
    </div>
  );
}