// Set of functions that can be used globally.
// Use by:
//   import * as Common from './Common.js';
//   ...
//   Then call whatever function you want:
//   Common.trimmedEventString(event, "name"); 



// import dayjsPkg from 'dayjs';
// const {dayjs} = dayjsPkg;
// const {utc} = utcPkg;
// import { customParseFormat } from 'dayjs/plugin/customParseFormat.js';
// const { customParseFormat } = pkg;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

// Javascript min/max dates:
export let maxDate = new Date(8640000000000000);
export let minDate = new Date(-8640000000000000);

// Trims a string (or null).  If empty, returns null -- otherwise the trimmed value.
export function trimmedStringOrNull(value) {
  if (null == value) {
    return null;
  }
  const trimmed = value.trim();
  if (0 === trimmed.length) {
    return null;
  }
  return trimmed;
}

// Pulls a value out of an event, trims it.  If empty, returns null -- otherwise the trimmed value.
export function trimmedEventString(event, name) {
  const value = event.target[name].value;
  return trimmedStringOrNull(value);
}

// Takes a string, trims it.  Returns the trimmed string converted to a float,
// null if empty or null string.  Throws errorMessage, if not empty and not a float.
export function trimmedFloatOrNull(floatString, errorMessage) {
  if ((null == floatString) || (0 === floatString.length)) {
    return null;
  }

  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(floatString)) {
    throw new Error(errorMessage);
  }

  const parsed = parseFloat(floatString);
  if (isNaN(parsed)) {
    throw new Error(errorMessage);
  }

  return parsed;
}


// Pulls a float out of an event, trims it.  Returns the trimmed string converted to a float,
// null if empty string.  Throws errorMessage, if not empty and not a float.
export function trimmedEventFloat(event, name, errorMessage) {
  const value = trimmedEventString(event, name);
  return trimmedFloatOrNull(value, errorMessage);
}


// In the DB, dates are stored in an ISO 8601 Date Time string ("YYYY-MM-DDThh:mm:ss.sssZ").
// The DatePicker control takes a Javascript Date object, translate to/from that.
export function dateStringToDate(dateString) {
  if ((null === dateString) || ("" === dateString) || (undefined === dateString)) {
    return null;
  }

  // I moved to date/time in database instead of just date, so now do this:
  return dayjs(dateString).toDate();

  // Dates are stored in the DB as YYYY-MM-DDThh:mm:ss.sssZ. If I pass that to new Date(), it
  // converts to local time zone and the date can be wrong (i.e. midnight 3/20/21 UTC
  // is 3/19/21 in Pacific timezone). dayjs makes the conversion correctly for me.
  // return dayjs(dateString, "YYYY-MM-DD").toDate();
}


// I use AWSDateTime in the database. These are ISO 8601 date and time formate (YYYY-MM-DDThh:mm:ss.sssZ),
// optionally with timezone offset ('YYYY-MM-DDThh:mm:ss.sss-07:00'.
export function databaseDateTimeString(date) {
  return dayjs(date).format();
}


// Add a number of days to a date.
export function datePlusDays(date, days) {
  let day = dayjs(date);
  let ret = day.add(days, 'day');
  return ret.toDate();
}


// Date to human readable string.
export function displayDate(date) {
  if (null === date) {
    return 'No date';
  }
  return dayjs(date).format('lll');
}


// Finds the given itemCounter in an item by the counter's ID.
// Returns null if not found.
export function itemCounterFromId(item, id) {
  if (!item || !item.itemCounter || !item.itemCounter.items.length) {
    console.error('No item counters?');
    return null;
  }

  for (var i=0; i < item.itemCounter.items.length; i++) {
    if (item.itemCounter.items[i].id === id) {
      return item.itemCounter.items[i];
    }
  }

  console.error(`No counter found for ${id}?`);
  return null;
}


// Given an Item and and itemCounterId, return the itemCounterId.name.
// Null if cannot find.
export function counterName(item, itemCounterId) {
  if (itemCounterId === null) {
    return '';
  }
  let counter = itemCounterFromId(item, itemCounterId);

  if (counter) {
    return counter.name;
  }

  return null;
}


// One of the task fields is the "Counter Based On".
// This is a "select" which lets the user pick from
// a list. The list is built at runtime with the
// item's counterArray.
export function buildFieldsForItem(fieldsWithoutSelect, item) {
  let fields = fieldsWithoutSelect;

  for (var i=0; i < fields.length; i++) {
    // Map the counter ID's to names.
    if ("itemCounterId" === fields[i].key) {
      let field = fields[i];

      field.selects = item.itemCounter.items.map( (counterItem, index) => {
        // index + 1 to make room for no-counter, set below.
        return {"id" : counterItem.id, "index" : index+1, "name" : counterItem.name }
      });

      // Allow selecting no-counter.
      field.selects.unshift({"id" : null, "index": 0, "name" : "(None)"})
      fields[i] = field;

      // Just the one to do...
      break;
    }
  }

  return fields;
}


// When writing to the database, an object needs to contain just the fields the
// database know about. I sometimes add extra fields (like taskBucket in a task).
// This copies an object and only adds the known fields, returning that item.
export function copyOnlyKnownFields(item, fields) {
  let output = {};
  for (var i=0; i < fields.length; i++) {
    if (item[fields[i].key]) {
      output[fields[i].key] = item[fields[i].key];
    }
  }
  return output;
}

