// Loads a list of top level items from the database and shows them.
// All Items that don't have a parentId associated with them.
// If you choose a top level item, loads the Item page, with the ID of that item passed to the Item page. 

import React, { useEffect, useState, useCallback } from 'react';
import { listTasksAndCountersForItemId, listItems, saveEditedItem, createItem } from '../utilities/Database';
import { Thumbnail } from './item/Thumbnail';
import { useStatusMessage, DataCard, ModalEditForm, ItemsHelp, Spinner } from '../components';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { urlForItem } from '../fleet-shared/Urls';
import { itemFields } from '../fleet-shared/ItemFields';
import { nameSoon, nameOverdue } from '../fleet-shared/TaskBuckets';

const TaskSummary = ({ itemId }) => {
  const [buckets, setBuckets] = useState(null);

  useEffect(() => {
    let mounted = true;

    // Fetch tasks/counters for count up overdue/soon tasks.
    async function fetchTasks() {
      try {
        if (!mounted) { return; }

        let [tasks] = await listTasksAndCountersForItemId(itemId);
        // console.log(`Fetched tasks/counters for ${itemId}`);

        let buckets = {
          total: 0,
          overdue: 0,
          soon:0
        }
        tasks.forEach((task) => {
          buckets.total = buckets.total + 1;
          if (task.bucketName === nameOverdue) {
            buckets.overdue = buckets.overdue + 1;
          } else if (task.bucketName === nameSoon) {
            buckets.soon = buckets.soon + 1;
          }
        });

        setBuckets(buckets);
      } catch (err) {
        if (!mounted) { return; }
      }
    }
    

    fetchTasks();

    return () => {
      mounted = false;
    }
  }, [itemId]);

  if (buckets) {
    return (
      <span>
        {buckets.total} tasks, {buckets.overdue} <span className='text-danger'><b>overdue</b></span>, {buckets.soon} <span className='text-warning'><b>due soon</b></span>.
      </span>
    );
  } else {
    return (
      <span><Spinner/><span className='p-2 text-secondary'>Loading tasks...</span></span>
    );
  }
}


// UI for an individual item.
const ItemRow = ({ identityId, itemId, name }) => {
  let navigate = useNavigate();

  const onClickRow = useCallback( () => {
    let itemUrl = urlForItem(itemId);
    navigate(itemUrl);
  }, [itemId, navigate]);

  return (
    <tr onClick={onClickRow} >
      <td className='col-2'>
      </td>

      <td align='right' className='col-1'>
        <Thumbnail identityId={identityId} itemId={itemId}/>
      </td>

      <td align='left' className='col-2 entered-data'>
        {name}
      </td>

      <td align='left' className='col-3'>
        <TaskSummary itemId={itemId} />
      </td>

      <td align='right' className='col-2'>
        <Button variant='primary' onClick={onClickRow}>
          Details
        </Button>
      </td>

      <td className='col-2'>
      </td>

    </tr>
  );
}


// Body of the table.
const Body = ({ items, identityId }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover text-nowrap table-sm">
        <tbody>
          {items.map((item, index) => <ItemRow key={index} identityId={identityId} itemId={item.id} name={item.name}/>)}
        </tbody>
      </table>
    </div>
  );
}

const AddItemButton = ({ addItem }) => {
  return (
    <div className="text-center">
      <Button variant="primary" className="mb-2" onClick={addItem}>
        Add Item
      </Button>
    </div>
  );
}


// Page of Items to select. Shows status for each item (# of tasks coming due, etc.)
const Items = ( { user } ) => {
  // Items to show
  const [items, setItems] = useState([]);

  // True after try to load items over network.
  const [isLoaded, setIsLoaded] = useState(false);

  // Edit Item UI.  When to show it, and what to show.
  const [showEditItem, setShowEditItem] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);

  // Loading/error UI
  const [StatusMessage, {showLoading, showText, showError, hideStatus}] = useStatusMessage();


  // When we startup, fetch our items. 
  useEffect(() => {
    let mounted = true;

    async function fetchItems() {
      try {
        if (!mounted) { return }

        showLoading();

        const filter = {
          or: [
            {parentId: { attributeExists: false } },
            { parentId: { eq: null } }
          ]
        };
        const items = await listItems(filter);
        if (!mounted) { return }

        setItems(items);
        hideStatus();
        setIsLoaded(true);
      } catch (err) { 
        if (!mounted) { return }
        showError(err);
      }
    }

    fetchItems()

    return () => {
      mounted = false;
    }
  }, [hideStatus, showError, showLoading]);


  // Passed to EditItem.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveItem = useCallback(async (newItem) => {
    // Go do the update -- can throw!
    const savedItem = await saveEditedItem(newItem, editItem);

    // Update the UI.
    var found = false;
    const cItems = items.length;
    for (var i=0; i < cItems; i++) {
      if (items[i].id === savedItem.id) {
        items[i] = savedItem;
        found = true;
        break;
      }
    }
    if (!found) {
      items.push(savedItem);
    }

    setShowEditItem(false);
  }, [items, editItem]);


  // User tapped add item button.
  const addItem = useCallback(async () => {
    showText("Creating...");

    try {
      // Add item to the database
      let newItem = {
        name: 'New item',
      };
      // Note: this can throw (e.g. network or security error)
      const createdItem = await createItem(newItem);

      // Put up UI to edit the new item
      setEditItem(createdItem);
      setShowEditItem(true);
      hideStatus();
    } catch(err) {
      showError(err);
    }
  }, [hideStatus, showError, showText]);

  return (
    <div>
      <StatusMessage/>
      {
        isLoaded &&
          <div>
            <DataCard
              header='Items'
              body=<Body identityId={user.identityId} items={items} />
              footer=<AddItemButton addItem={addItem} />
            />

            <ItemsHelp/>

            <ModalEditForm
              show={showEditItem}
              fields={itemFields}
              onHide={() => setShowEditItem(false)}
              item={editItem}
              save={saveItem}
            />
          </div>
      }
    </div>
  )
}  

export default Items

