// A part of the logbook / link to full logbook for display on an Item
//
//   itemId - itemId showing summary for
//   onAll called when all is clicked.
//   onAdded(entry) is called when a logbook entry was added.
///  onSaved(entry) is called when a logbook entry is edited and saved.
import { useState } from 'react';
import { useStatusMessage, LogbookEntrySummary, EditLogbookEntry, DataCard, LogbookHelp, PickLogbookAddType } from '.';
import Button from 'react-bootstrap/Button';
import * as Database from '../utilities/Database.mjs';
import { pageLogbookPickTask } from '../pages/item/useSharedItemPageState.jsx';

const LogbookSummary = ( {item, onAll, onAdded, onSaved, navigateTo } ) => {
    const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

    const [showEditLogbookEntry, setShowEditLogbookEntry] = useState(false);
    const [editLogbookEntry, setEditLogbookEntry] = useState(null);

    const [showPickLogbook, setShowPickLogbook] = useState(false);

    function didAdd(savedTask) {
      // Update the UI
      onAdded(savedTask);
      setEditLogbookEntry(savedTask);
      setShowEditLogbookEntry(true);
    }

    async function handleAdd() {
      setShowPickLogbook(false);
      await Database.createCompletedTaskWithUI(item.id, showText, showError, hideStatus, didAdd);
    }

    function handleSelectTaskForLogbook() {
        // Navigate to logbook/picktask
        navigateTo(pageLogbookPickTask);
    }


    // Passed to EditTask.  When the Save button is pressed, this is called.
    // throws on errors.
    async function saveEditedLogbookEntry(task) {
      // Copy ID's into the saved task.
      task.id = editLogbookEntry.id;
      task.itemId = item.id;

      // Update the database, can throw.
      let savedTask = await Database.updateCompletedTask(task, item);

      // And UI.
      onSaved(savedTask);            
      hideStatus();
      setShowEditLogbookEntry(false);
    }

    const Entries = () => {
        if (item) {
            if (item.completedTasks.items.length === 0) {
                return (
                    <LogbookHelp />
                );
            }
            return item.completedTasks.items.map( (entry) => (
                <LogbookEntrySummary key={entry.id} entry={entry} item={item} navigateTo={navigateTo}/>
            ));
        }
        return null;
    }


    return (
        <div>
            <DataCard
              header="Recent Logbook Entries"
              body={
                <div className="row justify-content-center">
                    <div className="text-center center mb-2">
                        {StatusMessage()}
                        <Entries />
                        <Button className='m-2' variant='primary' onClick={onAll}>
                            See All
                        </Button>

                        <Button className='m-2' variant='primary' onClick={() => setShowPickLogbook(true) }>
                          Add Entry
                        </Button>
                    </div>
                </div>
              }
            />

            <EditLogbookEntry
              show={showEditLogbookEntry}
              onHide={() => setShowEditLogbookEntry(false)}
              task={editLogbookEntry}
              item={item}
              save={saveEditedLogbookEntry}
            />

            <PickLogbookAddType 
                show={showPickLogbook}
                onHide={() => setShowPickLogbook(false)}
                onSelectTask={handleSelectTaskForLogbook}
                onAddAdHoc={handleAdd}
            />

        </div>
    );
}

export default LogbookSummary;