// Shows a card with a header, body, and footer.
//
// props.header = Text to show at top of the card
// props.body = Body to show in card
// props.footer = Object to render at footer (or null)

const DataCard = (props) => {
  return (
    <div className="container pt-2">
      <div className="card">
        <div className="card-header text-center">
          <h5>
            <span className="text-center">
              <strong>{props.header}</strong>
            </span>
          </h5>
        </div>

        <div className="card-body pt-1 pb-0 text-center">
          { props.body }
        </div>

        {
          (props.footer != null) && 
            <div className="card-footer">
              {props.footer}
            </div>
        }

      </div>
    </div>
  )
}

export default DataCard;
