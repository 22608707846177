// Shows a list of tasks. Has an URL of the form:
//    /item/:itemId/tasks/:taskList
// taskList = 'all' all tasks for the given ItemId
//          = 'overdue' all overdue tasks
//          = 'soon' for coming soon tasks.
//
// When called from another page, can include state so don't need to reload
// everything. state contains:
//    name - Name of this list (for breadcrumbs)
//    item - Item the tasks belong to
//    bucket - Bucket to show links to (see utilities/TaskBuckets)

import { DataCard, useStatusMessage } from '../../components';
import { useParams } from 'react-router-dom';
import { useSharedItemPageState, pageTasks, pageTask } from '../item/useSharedItemPageState';
import { TaskTable } from './TaskTable';
import { urlForTasks } from '../../fleet-shared/Urls';

const Tasks = (props) => {
  // Passed on URL, the item the task(s) belong to. Optionally, the single taskId being shown.
  const { itemId, listType } = useParams();

  // Network status info
  const [StatusMessage, {showLoading, showError, hideStatus}] = useStatusMessage();

  // Page data/state
  const [{ item, Breadcrumbs, navigateTo, taskBucket }] = useSharedItemPageState({
    page: pageTasks, 
    itemId: itemId,
    pageItemId: listType, 
    showLoading: showLoading, 
    hideLoading: hideStatus, 
    showError: showError
  });

  // A task was clicked, go edit it.
  function taskClicked(task) {
    navigateTo(pageTask, 
      task.id, 
      { 
        name: taskBucket.name, 
        url: urlForTasks(itemId, listType) 
      });
  }

  return (
    <div>
      <Breadcrumbs />
      <StatusMessage />

      { taskBucket && (
        <div>
          <DataCard
            header={taskBucket.name} 
            body={<TaskTable item={item} tasks={taskBucket.tasks} taskClicked={taskClicked} />}
          />
        </div>
      )}
    </div>
  );
}

export default Tasks;
