// EmailItem Page

// import { API } from 'aws-amplify';
// import { useState } from 'react';
import { useParams } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';

// import * as Urls from '../../fleet-shared/Urls.mjs';
import { useSharedItemPageState, pageEmailItem, pageItem } from './useSharedItemPageState';
import { useStatusMessage } from '../../components';
// TODO: Get rid of sendMail in schema?
// import { sendMail } from '../../graphql/mutations'; 
// import { emailItemInfo, emailCounters, emailTasks, emailLogbook } from '../../fleet-shared/Email.mjs';


import { EmailSubscriptionsCard } from './EmailSubscriptionsCard';



const EmailItem = ({ user }) => {
    // Passed on URL:
    const { itemId } = useParams();
    const [StatusMessage, {showLoading, showError, hideStatus}] = useStatusMessage();

    // Page data/state
    const [{ item, Breadcrumbs, navigateTo }] = useSharedItemPageState({
        page: pageEmailItem, 
        itemId: itemId, 
        showLoading: showLoading, 
        hideLoading: hideStatus, 
        showError: showError
    });

    return (
        <div className='text-center'>
            <Breadcrumbs />
            <StatusMessage />
            <EmailSubscriptionsCard item={item} user={user}/>
            <button className='btn btn-primary mt-2' onClick={() => navigateTo(pageItem)}>Back to {(item && item.name) || 'Item'}</button>
        </div>
    );
}

export default EmailItem;